// extracted by mini-css-extract-plugin
export var hero = "jv_ka";
export var hero_content = "jv_pa";
export var hero_descr = "jv_ma";
export var hero_inner = "jv_na";
export var hero_pic = "jv_sa";
export var hero_stats = "jv_bVa";
export var hero_stats_item = "jv_bWa";
export var hero_stats_title = "jv_bYa";
export var hero_stats_value = "jv_bXa";
export var hero_subtitle = "jv_bTa";
export var hero_title = "jv_qa";